<!--保存-->
<template>
  <el-dialog :visible.sync="show" width="40%">
    <div class="flex-column col-center">

      <div class="avatar-wrap">
        <el-avatar :size="50" :src="project.Logo" />
      </div>

      <el-form :model="editForm" ref="form" label-width="80px" style="width: 300px">
        <!-- <el-form-item label="商城名称" prop="Name">
          <span>{{ editForm.Name }}</span>
        </el-form-item> -->
        <el-form-item label="页面标题" prop="name" verify>
          <el-input type="text" v-model="editForm.name" />
        </el-form-item>
        <el-form-item label="页面描述" prop="remote">
          <el-input type="text" v-model="editForm.remote" />
        </el-form-item>
      </el-form>
    </div>

    <!-- submit -->
    <div slot="footer" class="dialog-footer flex-center">
      <el-button type="primary" :loading="savLoading" round style="width: 140px" @click="submit">保存</el-button>
    </div>

    <home-cover ref="home-cover" @complete="complete"></home-cover>
  </el-dialog>
</template>

<script>
import HomeCover from "./HomeCover.vue";
import { updateThemePageWidgets } from "@/api/project";
import { mapGetters, mapMutations } from "vuex";
import { getImageSize } from '@/utils/globalMethods.js'

export default {
  name: "SaveDialog",

  components: {
    HomeCover,
  },

  // inject: ["topBar"],

  data() {
    return {
      show: false,
      view: false,
      savLoading: false,
      editForm: {
        // Name: '',
        name: '',
        remote: ''
      }
    };
  },

  computed: {
    ...mapGetters(["project", "curPage", "userInfo"]),
  },

  methods: {
    ...mapMutations(["setCurPage"]),
    open(view) {
      console.log(this.curPage)
      this.savLoading = false
      this.$nextTick(() => {
        this.$refs["form"]?.clearValidate()
        // this.editForm.Name = this.project.Name
        this.editForm.name = this.curPage.name
        this.editForm.remote = this.curPage.remote
        this.show = true;
      })
      // console.log('view', view)
      // this.view = view;
    },

    /**
     * 更新封面
     * 成功更新封面图片,失败则更新为默认图片
     */
    changeCover() {
      this.$refs["home-cover"].init();
    },

    complete(result) {
      console.log("complete.....");
      console.log(result);

      if (result.status == 1) {
        this.$set(this.project, "cover", result.data);
        this.$notify({
          title: "成功",
          message: "封面生成成功!",
          type: "success",
        });
      } else {
        this.$set(
          this.project,
          "cover",
          "https://i.niupic.com/images/2022/11/02/aaa2.jpeg"
        );
        this.$notify({
          title: "失败",
          message: "封面生成失败,显示默认封面",
          type: "warning",
        });
      }
    },

    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.savLoading = true
          this.save();
        }
      });
    },
    // 保存
    save() {
      console.log('save', this.project.Name)
      const _curPage = this.$cloneDeep(this.curPage)
      _curPage.componentList = _curPage.componentList?.map(m => {
        if (m.component == 'McRecommendedGoods') {
          const { productType } = m.type
          m.productType = productType.id
          m.RecommendID = productType.RecommendID
          m.featureIDs = productType.featureIDs
        }
        if (m.component == 'McActivityGroup') {
          const { type } = m.list
          m.type = type.id
          m.promotionIDs = type.promotionIDs
          delete m.list
        }
        if (m.component == 'McCountdown') {
          m.value.startTime = m.value.startTime ? m.value.startTime : new Date()
          m.value.endTime = m.value.endTime ? m.value.endTime : new Date()
        }
        if (m.component == 'McSwiper') {
          m.height = m?.styles?.componentHeight
          m.MarginTop = m?.styles?.MarginTop
        } else {
          m.MarginTop = m?.space?.MarginTop
        }
        // 转化比例到原始图片比例
        if (m.component == 'McImgHot') {
          const _list = [...m.imageValue.HotspotList]
          m.imageValue.HotspotList = _list.map(_m => {
            _m.top = _m.top * m.imageValue.naturalWidth / 750
            _m.left = _m.left * m.imageValue.naturalWidth / 750
            _m.width = _m.width * m.imageValue.naturalWidth / 750
            _m.height = _m.height * m.imageValue.naturalWidth / 750
            return { ..._m }
          })
        }
        return { ...m }
      })
      const _data = {
        ..._curPage,
        name: this.editForm.name,
        remote: this.editForm.remote
      }
      this.setCurPage(this.curPage)
      updateThemePageWidgets(_data).then(res => {
        if (res) {
          this.show = false;
          this.$notify({
            title: "成功",
            message: "商城保存成功",
            type: "success",
          });
        }
      }).finally(() => {
        this.savLoading = false
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;

  .avatar-wrap {
    >img {
      width: 100%;
    }
  }
}

// input {
//   border: 0;
//   // margin-bottom: 5px;
// }

:deep(.el-form-item) {
  padding-bottom: 5px;
  // border-bottom: solid 1px #e3e3e3;

  .el-form-item__label {
    text-align: left;
  }

  // .el-form-item__error {
  //   top: 47px;
  //   left: -80px;
  // }
}

:deep(.el-button--primary) {
  background: $color-theme !important;
}

.cover {
  position: relative;

  .cover-title {
    margin: 10px 0 15px 0;
  }

  .cover-img {
    width: 300px;
    max-height: 400px;
    overflow-y: auto;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);

    // 隐藏滚动条
    &::-webkit-scrollbar {
      display: none;
      /* Chrome Safari */
    }
  }

  .cover-btn {
    position: absolute;
    top: 40px;
    right: -120px;
    z-index: 1;
  }
}
</style>