<!--实时预览弹窗-->
<template>
  <div class="RealTimeView">
    <el-dialog v-if="mShow" :append-to-body="true" :visible.sync="mShow" width="415px" top="8vh">
      <phone-ctn :head="false">
        <p class="project-title">{{ project.Name }}</p>
        <div style="height: 600px; overflow-y: auto;">
          <div class="render">
            <div v-for="item in curPage.componentList" :key="item.id">
              <component :is="item.component" :component-key="item.id" v-bind="item"
                :class="[['object', 'array'].includes(item.type) ? '' : 'event-none']">
              </component>
            </div>
            <!-- <div style="height: 100px; width: 100%; background-color: green; display: flex;overflow-X: auto;">
              <div v-for="item of 20"
                style="height: 100px; width: 100px;background-color: orange;border: 1px solid white;flex-shrink: 0;">{{
                  item }} </div>
            </div> -->
          </div>
        </div>
        <!-- <iframe id="cover" v-if="mShow" ref="iframe" class="screen" :scrolling="false" :src="iframeSrc"
          @load="load"></iframe> -->
      </phone-ctn>
    </el-dialog>
  </div>
</template>

<script>
import global from "@/config/global";
import { mapGetters } from "vuex";

export default {
  name: "RealTimeView",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      mShow: false,
    };
  },

  computed: {
    ...mapGetters(["project", "curPage"]),

    page() {
      return this.project?.pages?.find((page) => page.home);
    },

    // iframeSrc() {
    //   return `${global.viewUrl}pages/index/tabbar/home?operate=realTime`;
    // },
  },
  watch: {
    show: {
      immediate: true,
      handler(newValue, oldValue) {
        this.mShow = newValue;
      },
    },
    mShow: {
      immediate: true,
      handler(newValue, oldValue) {
        this.$emit("update:show", newValue);
      },
    },
  },

  methods: {
    load() {
      this.$nextTick(() => {
        this.$refs["iframe"] &&
          this.$refs["iframe"].contentWindow.postMessage(this.project, "*");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.project-title {
  color: #303133;
  font-size: 16px;
  font-weight: 700;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
}

:deep(.ctn) {
  margin: 0;

  .ctn-height-tag {
    display: none;
  }
}

.screen {
  width: 375px
    /*no*/
  ;
  height: 667px
    /*no*/
  ;
  border: 0;

  // 隐藏滚动条
  &::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }
}

:deep(.PhoneCtn) {
  width: 414px;
  margin: 0;

  .preview-height-tag {
    display: none;
  }

  .preview-body {
    height: 667px;
    // 隐藏滚动条
  }
}
</style>